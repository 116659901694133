import React,{useState, useEffect} from 'react';
import Layout from '../components/layout';

import SEO from "../components/seo.jsx"
import { Helmet } from 'react-helmet';
import { useTheme } from '../context/ThemeContext';



export const Head = () => (
  <SEO 
      title="STAIRAZ: Privacy Policy"
      description="     
      STAIRAZ Policy about User Privacy
      "
      pathname="/privacy/"

      >
        <html lang="en" />
 <meta name="keywords" content="AI-Powered Service, Job Application, Cover Letter Generators, Free Tools, STAIRAZ"/>
  </SEO>
);





const AboutUs = ({data}) => {
  const { isDarkMode } = useTheme();
  useEffect(() => {
    const link = document.getElementById('mode-stylesheet');
    if (link) {
      const additionalLink = document.createElement('link');
      additionalLink.rel = 'stylesheet';
      additionalLink.href = isDarkMode ? '/styles/page.css' : '/styles/pageLight.css';
      document.head.appendChild(additionalLink);

      return () => {
        document.head.removeChild(additionalLink);
      };
    }
  }, [isDarkMode]);
  
  return (
    <Layout>
       <Helmet>
        <link
          rel="stylesheet"
          href={isDarkMode ? '/styles/page.css' : '/styles/pageLight.css'}
        />
      </Helmet>
      <section className="section">
  <h1 className="section-heading">Privacy Policy</h1>
  <p className="section-paragraph">
  At <a href="https://www.stairaz.com/" target='_blank'><u>STAIRAZ</u></a>, we are committed to protecting the privacy and security of our users. This Privacy Policy outlines the types of personal information we collect, how we use it, and the steps we take to ensure that it is protected.
    <br/><br/>
    <strong>Types of Data Collected:</strong> We collect certain personally identifiable information that may include, but is not limited to, name, email address, age, or education background. We may also gather location data (e.g., region, IP address, GPS coordinates), user activity data (e.g., network monitoring, clicks, mouse position, scroll, keystroke logging), and content you interact with on websites (e.g., text, images, sounds, videos, hyperlinks).
    <br/><br/>
    <strong>Use of Data:</strong> The information we collect is used to provide, maintain, protect, and improve our services. This includes using your data to authenticate you, personalize your experience, and enhance the security of our service. Your information also helps us develop new features and functionalities that meet your needs as a user.
    <br/><br/>
    <strong>Data Protection:</strong> We implement a variety of security measures to maintain the safety of your personal information. These measures are designed to protect against unauthorized access, alteration, disclosure, or destruction of your personal data.
    <br/><br/>
    <strong>Third-Party Disclosure:</strong> We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We also ensure that the use or transfer of user data is strictly for purposes that are related to our extension's single purpose and not for unrelated purposes such as determining creditworthiness or for lending purposes.
    <br/><br/>
    <strong>Commitment to Privacy:</strong> We are dedicated to using your information solely for the purpose of enhancing your experience with our extension. We do not use or transfer your data for any purposes that are unrelated to the item's single purpose.
    <br/><br/>
    This policy is subject to change, and we encourage users to review it periodically. By using our service, you agree to the terms of this Privacy Policy. If you have any questions, please  <a href="https://www.stairaz.com/contact/" target='_blank'>contact us</a>.
  </p>
</section>

      <div className='divide-line'></div>

    </Layout>
  );
};

export default AboutUs;
